.Button {
    background-color:rgb(255, 182, 47);
    border-radius: 3px;
    box-shadow: 1px 1px 10px 4px rgb(82, 81, 81);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    margin: 5px;
    width: 6em;
    position: relative;
}

.Button:hover {
    background-color: rgb(250, 172, 28);
    cursor: pointer;
    border: none;
}

.Disabled {
    color: grey;
}

.Success {
    color: white;
    background-color: #57A8CC;
}

.Toggle {
    color: black;
    background-color: #ccc;
}
