.InputElement {
    display: inline-block;
    margin: 1em;
}

.Invalid {
    border: 1px solid red;
    background-color: rgb(252, 164, 155);
}

.TextArea {
    width: 100%;
    height: 180px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    border: solid 2px #999999;
}

textarea:focus {
    border: solid 2px #2965CC;
}
