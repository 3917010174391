.SelectStyle__menu-list::-webkit-scrollbar{
    width: 14px;
}

.SelectStyle__menu-list::-webkit-scrollbar-thumb{
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #999999;
    -webkit-box-shadow: inset -3px -3px 0px rgba(0, 0, 0, 0.05), inset 3px 3px 0px rgba(0, 0, 0, 0.05);
}

