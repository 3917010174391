.Wrapper {
    width: 400px;
    height: 80px;
}

.Container {
    width: 100%;
    height: 56px;
    position: relative;
    border: solid 2px #999999;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 40px 8px 16px;
    background-color: white;
}

.Focus {
    border: solid 2px #2965CC;
    box-shadow: 0 0 3px #E4EBF8;
}

.Error {
    border: solid 2px #E60000;            
}

.Filled {
    border: solid 2px #999999;
    box-shadow: 0 0 0;
}

.Disabled {
    border: solid 2px #B3B3B3;
    background-color: #F7F7F7;
}

.Container label {
    position: absolute;
    top: 20px;
    left: 16px;
    pointer-events: none;
    font-size: 16px;            
    color: #767676;
  }

.Container input {
    width: 100%;
    height: 56px;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 16px;
    color: #000000;
}

.Container input:focus ~ label {
    top: 8px;
    font-size: 12px;
}

.Filled input ~ label {
    font-size: 0px;
}

.Container input::placeholder {
    color: transparent;
}

.Container input:focus::placeholder {
    color: #767676;
}