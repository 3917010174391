.Modal {
    position: fixed;
    z-index: 500;
    background-color: lightgrey;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    border-radius: 4px;
    padding: 16px;
    left: 20%;
    top: 12%;
    box-sizing: border-box;
    transition: all .3s ease-out;
}

.Modal p{
    color: black;
}

@media (min-width: 600px) {
    .Modal {
        width: 60%;
        left: 20%;
    }
}