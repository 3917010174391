.Toolbar {
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 7%;
    padding-left: 0em;
    z-index: 2;
    background: rgb(255, 254, 254);
    margin-bottom: 2em;
}

.Logo {
    padding-top: .5em;
    padding-left: 2em;
    width: 12vw;
}
.Logo img{
    width: 100%;
}

.NavItems {
    display: flex;
    justify-content: center;
    margin-right: 0em;
}
.NavItems ul{
    display: flex;
    text-decoration: none;
    list-style: none;
}
.NavItems li{
    padding: 1em;
}

a {
    text-decoration: none;
    color: rgb(94, 93, 93);
}
a:hover {
    cursor: pointer;
    color: orange;
}

@media (min-width: 768px) {

    .Toolbar {
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 10%;
        padding-left: .5em;
        z-index: 2;
        background: rgb(255, 254, 254);
    }
    .Logo {
        padding-top: .5em;
        padding-left: 2em;
        width: 7vw;
    }
    .Logo img{
        width: 100%;
    }
    .NavItems {
        display: flex;
        justify-content: space-between;
        margin-right: .5em;
    }
    .NavItems ul{
        display: flex;
        text-decoration: none;
        list-style: none;
    }
    .NavItems li{
        padding: 2em;
    }
    
    a {
        text-decoration: none;
        color: rgb(94, 93, 93);
    }
    a:hover {
        cursor: pointer;
        color: orange;
    }
}
