body {
  background: rgb(94, 93, 93);
}
.Banner {
  margin-top: -4em;
  width: 100vw;
  height: 29vh;
  overflow: hidden;
  background: black;
}
.Banner img{
  position: relative;
  width: 100vw;
  top: -1em;
  opacity: .75;
}
.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 254, 254);
}
.Logo {
  padding-top: .5em;
  width: 35vw;
}
.Logo img{
  width: 100%;
}
.Heading{
  color: white;
  position: absolute;
  width: 100%;
  line-height: 0em;
  top: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
}
.Heading h2{
  font-size: 26px;
}
.Heading a{
  text-decoration: none;
  color: white;
}
.Heading a:hover{
  cursor: pointer;
  color: orange;
}
.Creds {
  padding-top: .5em;
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
}
.Footer {
  width: 100vw;
  background: rgb(94, 93, 93);
  color: white;
  padding-bottom: 2em;
}
.Footer a{
  color: orange;
}

@media only screen and (min-width: 380px) {
  .Banner {
    margin-top: -4em;
    height: 38vh;
  }
  .Banner img{
    top: -6em;
  }
}

@media only screen and (min-width: 768px) {
  .Banner {
    margin-top: -2em;
    height: 55vh;
  }
  .Banner img{
    top: -8em;
  }
  
  .Logo {
    margin-top: 2em;
    width: 30vw;
  }
 
  .Heading{
    color: white;
    position: absolute;
    width: 100%;
    line-height: .2em;
    top: 21%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 28px;
  }
  .Heading h2{
    font-size: 36px;
  }
  .Creds {
    margin-top: .5em;
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 22px;
  }
  .Footer {
    margin-top: 4em;
    width: 100vw;
    background: rgb(94, 93, 93);
    color: white;
    padding-top: 2em;
    padding-bottom: 4em;
  }
  .Footer a{
    color: orange;
  }
}

@media only screen and (min-width: 1100px) {
  .Banner {
    margin-top: -10em;
    height: 80vh;
  }
  .Banner img{
    top: -4em;
    position: relative;
    width: 100vw;
    opacity: .75;
  }
  .Logo {
    margin-top: 2em;
    width: 25vw;
  }
 
  .Heading{
    color: white;
    position: absolute;
    width: 100%;
    line-height: .2em;
    top: 21%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 48px;
  }
  .Heading h2{
    font-size: 36px;
  }
  .Creds {
    margin-top: .5em;
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 22px;
  }
  .Footer {
    margin-top: 4em;
    width: 100vw;
    background: rgb(94, 93, 93);
    color: white;
    padding-top: 2em;
    padding-bottom: 4em;
  }
  .Footer a{
    color: orange;
  }
}